/* global UID, firebase */
const firestore = firebase.firestore()
window.selectedStudent = null
var saveQue = []
var saving = false

function saveAttendance () {
  if (window.selectedStudent === null) return false
  let attendance = {
    access: UID,
    student: window.selectedStudent,
    date: document.getElementById('student-checkin-date').value,
    helper: parseInt(document.getElementById('student-checkin-points').innerText)
  }
  let checks = document.querySelectorAll('#student-checkin-marks img[active]')
  for (let x = 0; x < checks.length; x++) {
    attendance[checks[x].getAttribute('value')] = checks[x].getAttribute('active') === 'true'
  }
  if (window.selectedAttendance === null) firestore.collection('attendance').add(attendance)
  else firestore.collection('attendance').doc(window.selectedAttendance).set(attendance)
}

function getCheckin (id) {
  return firestore.collection('attendance').doc(id).get()
}

function getAttendanceList () {
  return firestore.collection('attendance').where('access', '==', UID).where('student', '==', window.selectedStudent).orderBy('date').get()
}

function deleteActiveAttendance () {
  firestore.collection('attendance').doc(window.selectedAttendance).delete()
}

function getUserList () {
  return firestore.collection('students').where('access', '==', UID).orderBy('name').get()
}

function loadUser () {
  firestore.collection('students').doc(this.id).get().then(doc => {
    let data = doc.data()
    let name = document.getElementById('student-name')
    let group = document.getElementById('student-group')
    let memo = document.getElementById('student-memo')
    name.disabled = group.disabled = memo.disabled = false
    name.value = data.name
    group.value = data.group
    memo.value = data.memo
    window.selectedStudent = doc.id
    document.getElementById('student-list-blur').style.display = 'none'
  })
}

function promptDeleteStudent () {
  if (window.selectedStudent !== null) {
    let response = window.prompt('If you are sure you would like to delete the active student, type "delete"')
    if (response.toLowerCase() === 'delete') {
      firestore.doc('students/' + window.selectedStudent).delete()
      window.selectedStudent = null
      let name = document.getElementById('student-name')
      name.value = ''
      name.disabled = true
      document.getElementById('student-group').disabled = true
      document.getElementById('student-memo').disabled = true
    }
  }
}

function saveStudentChanges () {
  let name = document.getElementById('student-name').value
  if (name === '') return false
  if (saving === true) {
    saveQue.push(this.onchange)
  } else if (window.selectedStudent === null) {
    saving = true
    let obj = {
      access: UID,
      name: name,
      group: document.getElementById('student-group').value,
      memo: document.getElementById('student-memo').value
    }
    firestore.collection('students').add(obj)
      .then(doc => {
        window.selectedStudent = doc.id
        if (saveQue.length > 0) saveQue.splice(0, 1)()
        else saving = false
      })
  } else {
    saving = true
    let obj = {}
    obj[this.dataset.saves] = this.value
    firestore.doc('students/' + window.selectedStudent).update(obj).then(() => {
      if (saveQue.length > 0) saveQue.splice(0, 1)()
      else saving = false
    })
  }
}

export { saveStudentChanges, promptDeleteStudent, getUserList, loadUser, saveAttendance, getAttendanceList, getCheckin, deleteActiveAttendance }
