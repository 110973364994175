import * as db from './models.js'

function filterList (ev) {
  if (ev.stopPropagation) ev.stopPropagation()
  let searchBar = document.getElementById('student-list-searchbar')
  searchBar.setAttribute('filter', this.getAttribute('filter'))
  let studentDivs = document.querySelectorAll('#student-list > div')
  for (let x = 0; x < studentDivs.length; x++) {
    let matchFilter = studentDivs[x].innerText.search(this.getAttribute('filter')) === -1
    let matchSearch = studentDivs[x].innerText.search(searchBar.value) === -1
    if (matchFilter || matchSearch) studentDivs[x].style.display = 'none'
    else studentDivs[x].style.display = 'block'
  }
}

function hideUserList () {
  document.getElementById('student-list-blur').style.display = 'none'
}

function showUserList () {
  let studentList = document.getElementById('student-list')
  studentList.innerHTML = ''
  db.getUserList().then(userList => {
    userList.forEach(user => {
      let div = document.createElement('div')
      div.innerHTML = `${user.get('name')}<sup>${user.get('group')}</sup>`
      div.id = user.id
      div.onclick = db.loadUser.bind(div)
      studentList.appendChild(div)
    })
    document.getElementById('student-list-blur').style.display = 'block'
  })
}

function dateFormat (timestamp) {
  let d = timestamp ? new Date(timestamp) : new Date()
  let day = d.getDate().toString().length === 1 ? ('0' + d.getDate()) : d.getDate()
  let monthInt = d.getMonth() + 1
  let month = monthInt.toString().length === 1 ? ('0' + monthInt) : monthInt
  return `${d.getFullYear()}-${month}-${day}`
}

function showCheckin () {
  let toggles = document.querySelectorAll('#student-checkin-marks img[active="true"]')
  for (let x = 0; x < toggles.length; x++) {
    toggles[x].setAttribute('active', 'false')
  }
  document.getElementById('student-checkin-blur').style.display = 'block'
  document.getElementById('student-checkin-date').value = dateFormat()
}

function hideCheckin () {
  document.getElementById('student-checkin-blur').style.display = 'none'
}

function loadAttendanceList () {
  if (window.selectedStudent === null) return false
  document.getElementById('student-attendance-list').innerText = ''
  db.getAttendanceList().then(docReference => {
    docReference.forEach(loadAttendanceListItem)
    document.getElementById('student-attendance-blur').style.display = 'block'
  })
}

function loadAttendanceListItem (attendance) {
  let div = document.createElement('div')
  div.innerText = attendance.get('date')
  div.id = attendance.id
  div.onclick = loadPreviousCheckin
  document.getElementById('student-attendance-list').appendChild(div)
}

function loadPreviousCheckin () {
  window.selectedAttendance = this.id
  db.getCheckin(this.id).then(attendance => {
    let data = attendance.data()
    let toggles = document.querySelectorAll('#student-checkin-marks img[active]')
    document.getElementById('student-checkin-date').value = data.date
    document.getElementById('student-checkin-points').innerText = data.helper
    for (let x = 0; x < toggles.length; x++) {
      let status = data[toggles[x].getAttribute('value')].toString()
      toggles[x].setAttribute('active', status)
    }
    hideAttendanceList()
    document.getElementById('student-checkin-blur').style.display = 'block'
  })
}

function hideAttendanceList () {
  document.getElementById('student-attendance-blur').style.display = 'none'
}

export { filterList, dateFormat, hideUserList, showUserList, showCheckin, hideCheckin, hideAttendanceList, loadAttendanceList }
