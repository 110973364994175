/* global firebase */

import * as db from './models.js'
import { filterList, hideUserList, showUserList, showCheckin, hideCheckin, hideAttendanceList, loadAttendanceList } from './functions.js'

window.onload = function () {
  let studentCheckinPoints = document.getElementById('student-checkin-points')
  let studentListFilterButtons = document.querySelectorAll('#student-list-pane .button')
  for (let x = 0; x < studentListFilterButtons.length; x++) {
    studentListFilterButtons[x].onclick = filterList
  }
  let autoSaveElements = document.querySelectorAll('[data-saves]')
  for (let x = 0; x < autoSaveElements.length; x++) {
    autoSaveElements[x].onchange = db.saveStudentChanges.bind(autoSaveElements[x])
  }
  document.getElementById('student-new-button').onclick = function () {
    window.selectedStudent = null
    autoSaveElements[0].value = '[New User]'
    autoSaveElements[1].selectedIndex = 0
    autoSaveElements[2].value = ''
    for (let x = 0; x < autoSaveElements.length; x++) {
      autoSaveElements[x].disabled = false
    }
  }
  let toggles = document.querySelectorAll('#student-checkin-marks img[active]')
  for (let x = 0; x < toggles.length; x++) {
    toggles[x].onclick = function () {
      if (this.getAttribute('active') === 'false') this.setAttribute('active', 'true')
      else this.setAttribute('active', 'false')
    }
  }
  document.getElementById('student-attendance-button').onclick = loadAttendanceList
  document.getElementById('student-list-searchbar').oninput = filterList
  document.getElementById('student-delete-button').onclick = db.promptDeleteStudent
  document.getElementById('student-list-pane').onclick = ev => ev.stopPropagation()
  document.getElementById('student-checkin-pane').onclick = ev => ev.stopPropagation()
  document.getElementById('student-attendance-pane').onclick = ev => ev.stopPropagation()
  document.getElementById('menu-pane').onclick = ev => ev.stopPropagation()
  document.getElementById('student-list-blur').onclick = hideUserList
  document.getElementById('student-attendance-blur').onclick = hideAttendanceList
  document.getElementById('student-attendance-hide-button').onclick = hideAttendanceList
  document.getElementById('student-select-button').onclick = showUserList
  document.getElementById('menu-button').onclick = function () {
    document.getElementById('menu-blur').style.display = 'block'
  }
  document.getElementById('menu-logout-button').onclick = function () {
    firebase.auth().signOut()
  }
  document.getElementById('menu-blur').onclick = function () {
    this.style.display = 'none'
  }
  document.getElementById('menu-close-button').onclick = function () {
    document.getElementById('menu-blur').style.display = 'none'
  }
  document.getElementById('student-checkin-delete').onclick = function () {
    if (window.selectedAttendance !== null) db.deleteActiveAttendance()
    hideCheckin()
  }
  document.getElementById('student-checkin-button').onclick = function () {
    if (window.selectedStudent === null) return false
    window.selectedAttendance = null
    studentCheckinPoints.innerText = 0
    showCheckin()
  }
  document.getElementById('student-checkin-save').onclick = function () {
    db.saveAttendance()
    hideCheckin()
  }
  document.getElementById('student-checkin-minus-point').onclick = function () {
    studentCheckinPoints.innerText = parseInt(studentCheckinPoints.innerText) - 1
  }
  document.getElementById('student-checkin-plus-point').onclick = function () {
    studentCheckinPoints.innerText = parseInt(studentCheckinPoints.innerText) + 1
  }
}
